import { DoubleRightOutlined, SyncOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classNames from "classnames";
import { Moon } from "icons/Moon";
import { Sun } from "icons/Sun";
import Flex from "utils/Flex";
import { Anchor } from "./Anchor";
import styles from "./Controls.module.scss";
import { useTheme } from "./Theme";

export const Controls = ({
  onSync,
  onScrollUp,
  syncAllowed = true,
  scrollUpVisible = false,
}: {
  onSync?: () => void;
  onScrollUp?: () => void;
  syncAllowed?: boolean;
  scrollUpVisible?: boolean;
}) => {
  const [isDarkMode, setIsDarkMode] = useTheme();

  return (
    <Anchor>
      <Flex.Col
        gap={16}
        style={{ padding: 16 }}
        className={classNames({ [styles.dark]: isDarkMode })}
      >
        <Button
          className={classNames(styles.themeButton, {
            [styles.invisible]: !scrollUpVisible,
          })}
          onClick={onScrollUp}
        >
          <DoubleRightOutlined style={{ transform: "rotate(-90deg)" }} />
        </Button>
        <Button
          className={styles.themeButton}
          onClick={() => {
            setIsDarkMode(!isDarkMode);
            localStorage.setItem("theme", isDarkMode ? "white" : "dark");
          }}
        >
          {isDarkMode ? <Sun /> : <Moon />}
        </Button>
        {syncAllowed && (
          <Button className={styles.refreshButton} onClick={onSync}>
            <SyncOutlined />
          </Button>
        )}
      </Flex.Col>
    </Anchor>
  );
};
