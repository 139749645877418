import { gapi } from "gapi-script";
import { getDocFromStorage } from "./utils";

export const downloadDoc = async (fileId: string): Promise<string> => {
  const folderData = getDocFromStorage(fileId);
  if (folderData && folderData.doc) return folderData.doc;

  const file = await gapi.client.drive.files.get({
    fileId,
  });
  if (file.result.mimeType.startsWith("text")) {
    const response = await gapi.client.drive.files.get({
      fileId,
      alt: "media",
    });
    return response.body;
  }

  const response = await gapi.client.drive.files.export({
    fileId,
    mimeType: "text/plain",
  });
  return response.body;
};
