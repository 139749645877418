import { sleep } from "utils/sleep";

export const DB = {
  name: "db",
  version: 1,
  domains: ["law", "doc", "image", "folder"],
  wait: 3,
};

export class Database {
  public name: string;
  public version: number;
  public initialized: boolean;
  public domains: string[];

  private static instance: Database;

  public static getInstance(): Database {
    if (!Database.instance) {
      Database.instance = new Database();
    }

    return Database.instance;
  }

  constructor(name = DB.name, version = DB.version, domains = DB.domains) {
    this.name = name;
    this.version = version;
    this.domains = domains;
    this.initialized = false;
  }

  async ready() {
    if (this.initialized) return;
    await this.init();
    this.initialized = true;
  }

  async open(): Promise<any> {
    await this.ready();
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.name, this.version);
      request.onsuccess = (event: any) => resolve(request);
      request.onerror = () => reject();
    });
  }

  async openConnection(): Promise<any> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.name, this.version);
      request.onsuccess = (event: any) => resolve(event);
      request.onerror = () => reject();
    });
  }

  async init() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.name, this.version);
      request.onupgradeneeded = (event: any) => {
        const db = event.target.result;
        for (const domain of this.domains) {
          db.createObjectStore(domain, { keyPath: "id" });
          db.createObjectStore(`backup-${domain}`, { keyPath: "id" });
        }
      };
      request.onsuccess = (event: any) => resolve(event);
      request.onerror = () => reject();
    });
  }

  async getById(objectStore: string, id: string): Promise<any> {
    const db = (await this.open()).result;
    const tx = db.transaction(objectStore, "readwrite");
    tx.oncomplete = () => db.close();
    const store = tx.objectStore(objectStore);
    const query = store.get(id);
    return new Promise((resolve, reject) => {
      query.onsuccess = () => resolve(query.result);
      query.onerror = () => reject();
    });
  }

  async getAll(objectStore: string): Promise<any[]> {
    const db = (await this.open()).result;
    const tx = db.transaction(objectStore, "readwrite");
    tx.oncomplete = () => db.close();
    const store = tx.objectStore(objectStore);
    const query = store.getAll();
    return new Promise((resolve, reject) => {
      query.onsuccess = () => resolve(query.result);
      query.onerror = () => reject();
    });
  }

  async insert(objectStore: string, data: any) {
    const db = (await this.open()).result;
    const tx = db.transaction(objectStore, "readwrite");
    tx.oncomplete = () => db.close();
    const store = tx.objectStore(objectStore);
    store.put(data);
  }

  async insertMultiple(objectStore: string, data: any[]) {
    const db = (await this.open()).result;
    const tx = db.transaction(objectStore, "readwrite");
    tx.oncomplete = () => db.close();
    const store = tx.objectStore(objectStore);
    for (const entry of data) {
      store.put(entry);
    }
  }

  async clear(objectStore: string) {
    const db = (await this.open()).result;
    const tx = db.transaction(objectStore, "readwrite");
    tx.oncomplete = () => db.close();
    const store = tx.objectStore(objectStore);
    store.clear();
  }

  async clearAll() {
    for (const domain of DB.domains) {
      await this.clear(domain);
    }
  }

  async backup() {
    for (const domain of DB.domains) {
      const domainData = await this.getAll(domain);
      const backupDomain = `backup-${domain}`;
      await this.clear(backupDomain);
      await this.insertMultiple(backupDomain, domainData);
    }
  }

  async restore() {
    for (const domain of DB.domains) {
      const backupDomain = `backup-${domain}`;
      const backupData = await this.getAll(backupDomain);
      await this.insertMultiple(domain, backupData);
    }
  }
}
