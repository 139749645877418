import { LeftCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import "antd/dist/reset.css";
import { loadFavoriteLaws } from "app/lawsSlice";
import classNames from "classnames";
import { AppDrawer } from "components/TreeDrawer";
import { Database } from "db/db";
import { syncStatisticsData } from "firebase/realtimeDatabase";
import { AnimatePresence, motion } from "framer-motion";
import { initGAPI } from "googleAPI/initGAPI";
import { syncGoogle } from "googleAPI/syncGoogle";
import { syncLaws } from "lawsAPI/syncLaws";
import { Docs } from "pages/docs";
import { Favourites } from "pages/favourites";
import { Law } from "pages/law";
import { Laws } from "pages/laws";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useNetworkState } from "react-use";
import { resolveRoute, routes } from "routes";
import { ConfirmUpdateModal } from "ui/ConfirmUpdateModal";
import { Controls } from "ui/Controls";
import { Footer } from "ui/Footer";
import { Header } from "ui/Header";
import { LoadErrorModal } from "ui/LoadErrorModal";
import { LoaderScreen } from "ui/LoaderScreen";
import { useTheme } from "ui/Theme";
import Flex from "utils/Flex";
import { incrementLinksCount } from "utils/incrementLinksCount";
import { Suspense } from "utils/useSuspense";
import "./App.css";

const DAY = 1000 * 60 * 60 * 24;

export const ABOUT_DRIVE_FILE = import.meta.env.VITE_ABOUT_DOCUMENT;
export const LIBRARY_DRIVE_FOLDER = import.meta.env.VITE_LIBRARY_FOLDER;
export const DOCS_DRIVE_FOLDER = import.meta.env.VITE_TREE_FOLDER;
export const SUBHEADER_ID = "subheaderId";

const AppRoutesLayout = () => {
  const location = useLocation();
  return (
    <motion.div
      key={location.pathname}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.1 } }}
      exit={{ opacity: 0 }}
      style={{ width: "100%", height: "100%" }}
    >
      <Outlet />
    </motion.div>
  );
};

export default function App() {
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);
  const [dark] = useTheme();
  const [modalVisible, setModalVisible] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const { online } = useNetworkState();
  const [scrollUpVisible, setScrollUpVisible] = useState<boolean>(false);
  const [loaded, setLoaded] = useState("uninitialized");
  const navigate = useNavigate();

  const refresh = async () => {
    setLoaded("pending");
    await initGAPI();
    // syncStatisticsData();
    const db = Database.getInstance();
    const theme = localStorage.getItem("theme");
    const favoriteLaws = localStorage.getItem("favoriteLaws");
    const treeSearchTags = localStorage.getItem("treeSearchTags");
    const driveTree = localStorage.getItem("driveTree");
    const lawsIds = localStorage.getItem("lawsIds");
    localStorage.clear();
    await db.backup();
    await db.clearAll();
    if (theme) localStorage.setItem("theme", theme);
    if (favoriteLaws) localStorage.setItem("favoriteLaws", favoriteLaws);
    try {
      // await Promise.all([syncGoogle(), syncLaws()]);
      await Promise.all([syncGoogle()]);
      localStorage.setItem("loaded", `${Date.now()}`);
      setLoaded("success");
    } catch (error) {
      console.log("error", error);
      await db.restore();
      localStorage.setItem("loaded", `${Date.now()}`);
      if (treeSearchTags)
        localStorage.setItem("treeSearchTags", treeSearchTags);
      if (driveTree) localStorage.setItem("driveTree", driveTree);
      if (lawsIds) localStorage.setItem("lawsIds", lawsIds);
      setLoaded("success");
      setModalVisible("loadError");
    }
  };

  // Disable IOS go back
  useEffect(() => {
    const listener = (e: any) => {
      if (e.pageX > 20 && e.pageX < window.innerWidth - 20) return;
      e.preventDefault();
    };
    document.addEventListener("touchstart", listener);
    return () => document.removeEventListener("touchstart", listener);
  }, []);

  // Update google and laws
  useEffect(() => {
    const storedLoaded = localStorage.getItem("loaded");
    // const loadOutDated = Number(storedLoaded) < Date.now() - DAY;
    if (!online) {
      setLoaded("success");
      return;
    }
    if (!storedLoaded) {
      refresh();
    } else {
      setLoaded("success");
    }
  }, []);

  // Restore favorites
  useEffect(() => {
    dispatch(loadFavoriteLaws());
  }, []);

  useEffect(() => {
    const db = Database.getInstance();
    const { pathname } = location;
    const id = pathname.split("/").pop();
    if (!id) return;
    if (pathname.startsWith(routes.docs) && pathname !== routes.docs) {
      (async () => {
        const doc = await db.getById("doc", id);
        if (!doc) return;
        incrementLinksCount(JSON.stringify([doc.name, id]));
      })();
    }
    if (pathname.startsWith(routes.laws) && pathname !== routes.laws) {
      (async () => {
        const law = await db.getById("law", id);
        if (!law) return;
        incrementLinksCount(JSON.stringify([law.name, id]));
      })();
    }
    if (
      pathname.startsWith(routes.favourites) &&
      pathname !== routes.favourites
    ) {
      (async () => {
        const doc = await db.getById("doc", id);
        const law = await db.getById("law", id);
        if (!law && !doc) return;
        incrementLinksCount(JSON.stringify([law.name || doc.name, id]));
      })();
    }
  }, [location]);

  if (loaded !== "success") return <LoaderScreen />;

  const backInvisible =
    location.pathname === routes.docs ||
    location.pathname === routes.laws ||
    location.pathname === routes.favourites;

  return (
    <Flex.Col fullHeight className={classNames("app", { dark })}>
      <ConfirmUpdateModal
        visible={modalVisible === "confirmUpdate"}
        onCancel={() => setModalVisible("")}
        onOk={() => {
          setModalVisible("");
          refresh();
        }}
      />
      <LoadErrorModal
        visible={modalVisible === "loadError"}
        onCancel={() => setModalVisible("")}
        onOk={() => setModalVisible("")}
      />
      <Header onBurgerClick={() => setDrawerOpen(true)} />
      <Flex.Row style={{ padding: "0px 8px" }}>
        <Flex.Item flex={0}>
          <Button
            className="backButton"
            style={{
              opacity: backInvisible ? 0 : 1,
              visibility: backInvisible ? "hidden" : "visible",
              marginLeft: backInvisible ? -28 : 0,
              transition: "all 0.2s ease-out",
            }}
            icon={<LeftOutlined />}
            type="text"
            onClick={() => navigate(-1)}
          />
        </Flex.Item>
        <Flex.Item flex={1} id={SUBHEADER_ID} />
      </Flex.Row>
      <AppDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
      <Flex.Item
        flex={1}
        fixedHeight
        style={{ position: "relative", marginTop: 8 }}
      >
        <div
          ref={ref}
          onScroll={(e: any) => {
            setScrollUpVisible(e.target.offsetHeight + 10 < e.target.scrollTop);
          }}
          className="appBody"
        >
          <Suspense>
            <AnimatePresence mode="wait">
              <Routes location={location} key={location.pathname}>
                <Route element={<AppRoutesLayout />}>
                  <Route path={routes.docs} element={<Docs />} />
                  <Route path={routes.doc} element={<Docs />} />
                  <Route path={routes.favourites} element={<Favourites />} />
                  <Route path={routes.law} element={<Law />} />
                  <Route path={routes.laws} element={<Laws />} />
                  <Route
                    path={routes.about}
                    element={
                      <Navigate
                        to={resolveRoute("doc", { id: ABOUT_DRIVE_FILE })}
                        replace
                      />
                    }
                  />
                  <Route
                    path={routes.library}
                    element={
                      <Navigate
                        to={resolveRoute("doc", { id: LIBRARY_DRIVE_FOLDER })}
                        replace
                      />
                    }
                  />
                  <Route path="*" element={<Navigate to={routes.docs} />} />
                </Route>
              </Routes>
            </AnimatePresence>
          </Suspense>
        </div>
        <Controls
          scrollUpVisible={scrollUpVisible}
          onScrollUp={() => ref.current?.scroll({ top: 0, behavior: "smooth" })}
          syncAllowed={online}
          onSync={() => setModalVisible("confirmUpdate")}
        />
      </Flex.Item>
      <Footer />
    </Flex.Col>
  );
}
