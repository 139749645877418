import { DOCS_DRIVE_FOLDER } from "App";
import { format } from "date-fns";
import { uk } from "date-fns/locale";
import { Database } from "db/db";
import { getElSrcFromImageSrc } from "utils";

export type DriveItem =
  | { markdown: string; title: string; tags: string[]; subTitle: string }
  | {
      folders: {
        mimeType: string;
        icon?: JSX.Element;
        title: string;
        id: string;
      }[];
    };

export const getDriveItem = async (
  id = DOCS_DRIVE_FOLDER
): Promise<DriveItem | null> => {
  const db = Database.getInstance();
  const folder: any = await db.getById("folder", id);
  if (folder) {
    const folders = await Promise.all(
      folder.children.flatMap(async (childId: any) => {
        const doc: any = await db.getById("doc", childId);
        if (doc) {
          return [
            {
              id: doc.id,
              title: doc.name,
              mimeType: doc.mimeType,
            },
          ];
        }
        const folder: any = await db.getById("folder", childId);
        if (!folder) return [];
        const icon = folder.icon
          ? await db.getById("image", folder.icon.src)
          : undefined;
        return [
          {
            icon,
            mimeType: folder.mimeType,
            title: folder.name,
            id: folder.id,
          },
        ];
      })
    );
    return { folders: folders.flat() };
  }
  const markdown: any = await db.getById("doc", id);

  if (markdown) {
    const tagsContentSeparator = "\r\n";
    const tagsSeparator = ",";
    const [tagsLine, ...markdownLines] =
      markdown.doc.split(tagsContentSeparator);
    const prefix = "tags: ";
    const title = format(new Date(markdown.createdTime), "HH:mm, d MMMM y", {
      locale: uk,
    });

    if (tagsLine.includes(prefix)) {
      return {
        markdown: markdownLines.join(tagsContentSeparator),
        title,
        subTitle: markdown.name,
        tags: tagsLine.slice(prefix.length).split(tagsSeparator),
      };
    }
    return {
      markdown: markdown.doc,
      title,
      subTitle: markdown.name,
      tags: [],
    };
  }
  return null;
};
