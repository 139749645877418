import { ReactNode } from "react";
import styles from "./Anchor.module.scss";

export interface AnchorProps {
  children?: ReactNode;
  bottom?: number;
  right?: number;
  onClick?: () => void;
}

export const Anchor = ({
  children,
  bottom,
  right,
  onClick,
}: AnchorProps): JSX.Element => {
  return (
    <div
      className={styles.anchor}
      style={{
        bottom,
        right,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
