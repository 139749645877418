import { Typography } from "antd";
import { addFavoriteLaw, removeFavoriteLaw } from "app/lawsSlice";
import { AppState } from "app/store";
import classNames from "classnames";
import { Database } from "db/db";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce, useUpdateEffect } from "react-use";
import { resolveRoute } from "routes";
import { useHeaderValue } from "ui/HeaderContext";
import { Subheader } from "ui/Subheader";
import { useTheme } from "ui/Theme";
import { getAppLinkToLaw, getElSrcFromImageSrc } from "utils";
import { escapeRegExpString } from "utils/escapeRegExpString";
import Flex from "utils/Flex";
import { mapHTMLTree, parseHTML, stringifyHTML } from "utils/HTML";

let imageCache: Record<string, string> = {};

const RenderImage = (props: any) => {
  const [v, setV] = useState(0);

  useEffect(() => {
    if (!imageCache[props.src]) {
      (async () => {
        const id = props.src.slice("https://drive.google.com/file/d/".length);
        const db = Database.getInstance();
        const image: any = await db.getById("image", id);
        if (!image) return null;
        const { mimeType, data } = image;
        const res = getElSrcFromImageSrc(mimeType, data);
        imageCache = {};
        imageCache[props.src] = res;
        setV(v + 1);
      })();
    }
  }, []);

  return (
    <img
      style={{ width: "100%" }}
      {...props}
      src={imageCache[props.src] || ""}
      alt={props.src}
    />
  );
};

let linkCache: Record<string, string> = {};

const RenderLawLink = (props: any) => {
  const [v, setV] = useState(0);

  useEffect(() => {
    if (!linkCache[props.src]) {
      (async () => {
        const url = new URL(props.href);
        if (url.origin !== import.meta.env.VITE_LAWS_ORIGIN)
          linkCache[props.href] = url.toString();
        const path = url.pathname.split("/");
        const id = path.pop() ?? "";
        const db = Database.getInstance();
        const law: any = await db.getById("law", id);
        if (law) {
          linkCache[props.href] = law
            ? getAppLinkToLaw(props.href) || ""
            : import.meta.env.VITE_LAWS_ORIGIN +
              "/laws/show/" +
              id +
              window.location.hash;
          setV(v + 1);
        }
        const doc: any = await db.getById("doc", id);
        if (doc) {
          linkCache[props.href] = resolveRoute("doc", { id });
          setV(v + 1);
        }
      })();
    }
  }, []);

  return <a {...props} href={linkCache[props.href]} />;
};

const DEBOUNCE_DELAY = 300;
const SCROLL_DELAY = 300;

export default ({
  markdown,
  title,
  subTitle,
  tags,
  id,
}: {
  markdown: string;
  id: string;
  title: string;
  subTitle: string;
  tags: string[];
}) => {
  const favourites = useSelector((state: AppState) => state.laws.favoriteLaws);
  const dispatch = useDispatch();
  const [dark] = useTheme();

  const [value] = useHeaderValue();
  const [debouncedValue, setDebouncedValue] = useState<string | undefined>("");

  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(value);
    },
    DEBOUNCE_DELAY,
    [value]
  );
  const textBackupRef = useRef<string | null>(null);

  useUpdateEffect(() => {
    if (!textBackupRef.current) {
      textBackupRef.current =
        document.getElementsByClassName("markdown")?.[0].outerHTML;
    }
    if (!textBackupRef.current) return;
    let currentNode = false;
    const tree = parseHTML(textBackupRef.current);
    if (!tree) return;
    // tree.tag = `div className="markdown ${dark ? "dark" : ""}"`;
    const mappedTree = mapHTMLTree(tree, (node) => {
      if (typeof node !== "string" || !debouncedValue) return node;
      const trimmed = debouncedValue.trim();
      const re = new RegExp(escapeRegExpString(trimmed), "gi");
      let newText = node.replace(re, (m) => {
        const dataAttribute = !currentNode ? "" : 'data-scroll-to="true"';
        return `<span class="searchedText" ${dataAttribute}>${m}</span>`;
      });
      if (!currentNode) currentNode = true;
      return newText;
    });

    const el = document.getElementsByClassName("markdown")?.[0];
    if (el) el.outerHTML = stringifyHTML(mappedTree);

    const t = setTimeout(() => {
      const el = document.querySelector('[data-scroll-to="true"]');
      if (el)
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
    }, SCROLL_DELAY);

    return () => {
      clearTimeout(t);
    };
  }, [debouncedValue]);

  useEffect(() => {
    const el = document.getElementsByClassName("markdown")?.[0];
    if (!el) return;
    if (dark) el.classList.add("dark");
    else el.classList.remove("dark");
  }, [dark]);

  return (
    <Flex.Col gap={16}>
      <Subheader
        favorite={favourites.includes(id)}
        onFavorite={() => {
          if (id === "") return;
          if (favourites.includes(id)) {
            dispatch(removeFavoriteLaw(id));
          } else {
            dispatch(addFavoriteLaw(id));
          }
        }}
      />
      {subTitle && (
        <Typography.Title level={3} style={{ margin: 0 }}>
          {subTitle}
        </Typography.Title>
      )}
      <ReactMarkdown
        className={classNames("markdown", { dark })}
        components={{
          img: (props) => <RenderImage {...props} />,
          a: (props) => <RenderLawLink {...props} />,
        }}
      >
        {markdown}
      </ReactMarkdown>
    </Flex.Col>
  );
};
