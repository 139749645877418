import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import googleSlice from "./googleSlice";
import lawsSlice from "./lawsSlice";

const rootReducer = combineReducers({
  laws: lawsSlice,
  google: googleSlice,
});
const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof rootReducer>;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
