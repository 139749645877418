import { DOCS_DRIVE_FOLDER } from "App";
import { ROOT_FOLDER_ID } from "./getList";
import { GoogleTreeItem } from "./types";
import { mapGoogleTreeItems } from "./utils";

export const getList = async (
  id: string,
  pageToken: string
): Promise<{
  data: GoogleTreeItem[];
  token: string;
}> => {
  // @ts-ignore
  const response = await gapi.client.drive.files.list({
    fields: "nextPageToken, files(id, name, parents, mimeType, createdTime)",
    pageSize: 100,
    pageToken,
    q: `'${id}' in parents`,
  });

  return {
    data: response.result.files
      ? mapGoogleTreeItems(response.result.files)
      : [],
    token: response.result.nextPageToken || "",
  };
};

export const getAll = async (id = DOCS_DRIVE_FOLDER) => {
  let files: GoogleTreeItem[] = [];
  let nextPageToken = "";

  do {
    const { data, token } = await getList(id, nextPageToken);
    files.push(...data);
    nextPageToken = token;
  } while (nextPageToken);

  const folders = files.filter(
    (item) => item.mimeType === "application/vnd.google-apps.folder"
  );
  const nestedFiles = await Promise.all(folders.map(({ id }) => getAll(id)));

  files.push(...nestedFiles.flat());

  return files;
};
