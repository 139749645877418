import { RightOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import classNames from "classnames";
import { useTheme } from "ui/Theme";
import styles from "./Law.module.scss";

export interface LawProps {
  className?: string;
  favorite?: boolean;
  title?: string;
  subtitle?: string;
  onFavorite?: (isFavorite: boolean) => void;
  onClick?: () => void;
}
export interface FavouriteButtonProps {
  className?: string;
  favorite?: boolean;
  onClick?: (isFavorite: boolean) => void;
}

export const FavouriteButton = ({
  className,
  favorite,
  onClick,
}: FavouriteButtonProps): JSX.Element => {
  const [isDarkMode] = useTheme();

  return (
    <Button
      type="text"
      onClick={(e) => {
        e.preventDefault();
        onClick?.(!favorite);
      }}
      icon={
        <>
          {!favorite && (
            <StarOutlined
              className={classNames(
                styles.favorite,
                { [styles.dark]: isDarkMode },
                className
              )}
            />
          )}
          {favorite && (
            <StarFilled
              className={classNames(
                styles.favoriteActive,
                { [styles.dark]: isDarkMode },
                className
              )}
            />
          )}
        </>
      }
    />
  );
};

export const Law = ({
  className,
  title,
  subtitle,
  favorite,
  onFavorite,
  onClick,
}: LawProps): JSX.Element => {
  const [isDarkMode] = useTheme();

  return (
    <div
      className={classNames(
        styles.box,
        { [styles.dark]: isDarkMode },
        className
      )}
      onClick={onClick}
    >
      <FavouriteButton favorite={favorite} onClick={onFavorite} />
      <div className={styles.textBox}>
        <Typography.Title className={styles.title} level={5}>
          {title}
        </Typography.Title>
        <Typography.Text type="secondary">{subtitle}</Typography.Text>
      </div>
      <RightOutlined className={styles.right} />
    </div>
  );
};
