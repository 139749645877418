import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { DOCS_DRIVE_FOLDER } from "App";
import { Database } from "db/db";
import { FolderFallback } from "icons/FolderFallback";
import { PanelFallback } from "icons/PanelFallback";
import { useMemo, useState } from "react";
import { useAsync, useDebounce } from "react-use";
import { resolveRoute } from "routes";
import { useHeaderValue } from "ui/HeaderContext";
import { Panel } from "ui/Panel";
import { Subheader } from "ui/Subheader";
import { getElSrcFromImageSrc } from "utils";
import Flex from "utils/Flex";
import styles from "./FoldersView.module.scss";

const DEBOUNCE_DELAY = 300;

export default ({
  parentId,
  folders,
}: {
  parentId?: string;
  folders: {
    icon?: JSX.Element;
    title: string;
    id: string;
    mimeType: string;
  }[];
}) => {
  const [value = ""] = useHeaderValue();
  const [debouncedValue, setDebouncedValue] = useState<string | undefined>("");

  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(value);
    },
    DEBOUNCE_DELAY,
    [value]
  );

  const treeSearchTags: Record<string, string[]> = useMemo(() => {
    return JSON.parse(localStorage.getItem("treeSearchTags") || "{}") || {};
  }, [localStorage.getItem("treeSearchTags") || "{}"]);

  const { value: foldersData } = useAsync(async () => {
    if (!debouncedValue) return folders;
    const db = Database.getInstance();
    const ids = [
      ...new Set(
        Object.entries(treeSearchTags)
          .filter(([key]) => !!key.match(new RegExp(debouncedValue, "gi")))
          .flatMap(([, ids]) => ids)
      ),
    ];
    const entries = await Promise.all(
      ids.map((id) => {
        const slash = id.indexOf("-");
        const table = id.slice(0, slash);
        const _id = id.slice(slash + 1);
        return db.getById(table, _id);
      })
    );
    return await Promise.all(
      entries.map(async (entry: any) => {
        const icon = entry.icon
          ? await db.getById("image", entry.icon.src)
          : undefined;
        return {
          ...entry,
          title: entry.name,
          icon,
        };
      })
    );
  }, [debouncedValue, folders]);

  const foldersToRender = (foldersData || [])
    .sort(({ title: a = "" }, { title: b = "" }) => {
      const matchA = a.match(new RegExp("[0-9]*_"));
      const matchB = b.match(new RegExp("[0-9]*_"));
      if (!!matchA && !matchB) return -1;
      if (!matchA && !!matchB) return 1;
      if (!!matchA && !!matchB) {
        const [textA] = matchA;
        const [textB] = matchB;
        const textAMapped = Number(textA.slice(0, textA.length - 1)) || 0;
        const textBMapped = Number(textB.slice(0, textB.length - 1)) || 0;
        return textAMapped - textBMapped;
      }
      return a.localeCompare(b);
    })
    .map(({ icon, id, title, mimeType }) => {
      const match = title.match(new RegExp("[0-9]*_"));
      const _title = !match ? title : title.slice(match[0].length);
      const _icon =
        mimeType === "application/vnd.google-apps.folder" ? (
          icon ? (
            <img src={getElSrcFromImageSrc(icon.mimeType, icon.data)} />
          ) : (
            <FolderFallback />
          )
        ) : undefined;
      return {
        id,
        title: _title,
        icon: _icon,
      };
    });

  return (
    <Flex.Col gap={16}>
      <Subheader hideFavourite />
      {parentId !== DOCS_DRIVE_FOLDER &&
        foldersToRender.map(({ icon, id, title }) => {
          return (
            <Flex.Item key={id} flex={1}>
              <Panel
                title={title}
                icon={icon}
                link={resolveRoute("doc", { id })}
              />
            </Flex.Item>
          );
        })}
      {parentId === DOCS_DRIVE_FOLDER && (
        <Flex.Row gap={16} wrap>
          {foldersToRender.map(({ icon, id, title }) => {
            return (
              <Flex.Col
                key={id}
                flex={1}
                style={{ minWidth: "calc(50% - 8px)" }}
              >
                <Panel
                  title={title}
                  icon={icon}
                  link={resolveRoute("doc", { id })}
                  zipTitle
                />
              </Flex.Col>
            );
          })}
        </Flex.Row>
      )}
    </Flex.Col>
  );
};
