import { DOCS_DRIVE_FOLDER } from "App";
import DocView from "components/DocView";
import FoldersView from "components/FoldersView";
import { getDriveItem } from "googleAPI/getDriveItem";
import { useParams } from "react-router-dom";
import { useAsync } from "react-use";
import { useTheme } from "ui/Theme";
import Flex from "utils/Flex";

export const Docs = () => {
  const [isDark] = useTheme();
  const { id = DOCS_DRIVE_FOLDER } = useParams();
  const { value: item, loading } = useAsync(async () => getDriveItem(id), [id]);
  if (!item && !loading)
    return (
      <Flex
        fullHeight
        fullWidth
        justify="center"
        align="center"
        style={{ color: isDark ? "#fff" : undefined }}
      >
        Не знайдено
      </Flex>
    );
  if (!item) return <></>;
  if ("markdown" in item) return <DocView {...item} id={id} />;
  return <FoldersView parentId={id} folders={item.folders} />;
};
