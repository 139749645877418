import { concatUrl } from "utils/concatUrl";
import { retry } from "utils/retry";
import { sleep } from "utils/sleep";

const DELAY = 500;

const fetchLaw = async (id: string) => {
  const url = concatUrl(
    import.meta.env.VITE_LAW_API_HOST,
    "/law/show",
    `${id}`
  );
  const res = await fetch(url, {});
  const html = await res.text();
  return html;
};

export const getLawList = async (ids: string[]) => {
  const laws = [];
  for (const id of ids) {
    await sleep(DELAY);
    const html = await fetchLaw(id);
    // if (html instanceof Error) throw new Error(`Failed to load law ${id}`);

    laws.push({ id, html });
  }
  return laws;
};
