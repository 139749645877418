import { gapi } from "gapi-script";

const discoveryDocs = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
  "https://www.googleapis.com/discovery/v1/apis/sheets/v4/rest",
];

export const initGAPI = () =>
  new Promise((resolve) => {
    gapi.load("client", async () => {
      await gapi.client.init({
        apiKey: import.meta.env.VITE_API_KEY,
        discoveryDocs,
      });
      resolve(gapi.client);
    });
  });
