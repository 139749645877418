import store from "app/store";
import * as React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ErrorScreen } from "ui/ErrorScreen";
import { HeaderContextProvider } from "ui/HeaderContext";
import { ThemeContextProvider } from "ui/Theme";
import ErrorBoundary from "utils/ErrorBoundary";
import App from "./App";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeContextProvider>
      <BrowserRouter>
        <HeaderContextProvider>
          <ErrorBoundary component={<ErrorScreen />}>
            <App />
          </ErrorBoundary>
        </HeaderContextProvider>
      </BrowserRouter>
    </ThemeContextProvider>
  </Provider>
  // </React.StrictMode>
);
