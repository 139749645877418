import { gapi } from "gapi-script";

export const downloadImage = async (fileId: string): Promise<string> => {
  const response = await gapi.client.drive.files.get({
    fileId,
    alt: "media",
    // mimeType: "application/png",
  });
  return window.btoa(response.body);
};
