import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { syncLaws } from "lawsAPI/syncLaws";

export const fetchLaws = createAsyncThunk("laws/fechLaws", syncLaws);

export type LawState = {
  favoriteLaws: string[];
};

const initialState: LawState = {
  favoriteLaws: [],
};

export const lawsSlice = createSlice({
  name: "laws",
  initialState,
  reducers: {
    addFavoriteLaw: (state, action) => {
      if (state.favoriteLaws.includes(action.payload)) return;
      const newFavoriteLaws = [action.payload, ...state.favoriteLaws];
      window.localStorage.setItem(
        "favoriteLaws",
        JSON.stringify(newFavoriteLaws)
      );
      state.favoriteLaws = newFavoriteLaws;
    },
    removeFavoriteLaw: (state, action) => {
      if (!state.favoriteLaws.find((id) => id === action.payload)) return;
      const newFavoriteLaws = state.favoriteLaws.filter(
        (id) => id !== action.payload
      );
      window.localStorage.setItem(
        "favoriteLaws",
        JSON.stringify(newFavoriteLaws)
      );
      state.favoriteLaws = newFavoriteLaws;
    },
    replaceFavoriteLaws: (state, action) => {
      window.localStorage.setItem(
        "favoriteLaws",
        JSON.stringify(action.payload)
      );
      state.favoriteLaws = action.payload;
    },
    loadFavoriteLaws: (state) => {
      const lawsFromStorage = window.localStorage.getItem("favoriteLaws");
      const laws = lawsFromStorage ? JSON.parse(lawsFromStorage) : [];
      state.favoriteLaws = laws;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  // setToken,
  addFavoriteLaw,
  removeFavoriteLaw,
  replaceFavoriteLaws,
  loadFavoriteLaws,
} = lawsSlice.actions;

export default lawsSlice.reducer;
