import {
  BookFilled,
  BookOutlined,
  HomeFilled,
  HomeOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { Card } from "antd";
import classNames from "classnames";
import styles from "./Footer.module.scss";
import { NavLink, useLocation } from "react-router-dom";
import { resolveRoute } from "routes";
import { useTheme } from "./Theme";

const ITEMS = [
  {
    icon: <HomeOutlined />,
    activeIcon: <HomeFilled />,
    title: "Алгоритми",
    link: resolveRoute("docs", {}),
  },
  {
    icon: <BookOutlined />,
    activeIcon: <BookFilled />,
    title: "Закони",
    link: resolveRoute("laws", {}),
  },
  {
    icon: <StarOutlined />,
    activeIcon: <StarFilled />,
    title: "Обране",
    link: resolveRoute("favourites", {}),
  },
];

export const Footer = (): JSX.Element => {
  const [isDarkMode] = useTheme();
  const location = useLocation();
  return (
    <Card>
      <div className={styles.box}>
        {ITEMS.map(({ activeIcon, icon, title, link }, index) => {
          const isActive = location.pathname.startsWith(link);
          return (
            <NavLink
              to={link}
              key={index}
              className={classNames(styles.tab, {
                [styles.active]: isActive,
                [styles.dark]: isDarkMode,
              })}
            >
              {isActive ? activeIcon : icon} {title}
            </NavLink>
          );
        })}
      </div>
    </Card>
  );
};
