import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

const HeaderContext = React.createContext<{
  value: string | undefined;
  setValue: (value: string | undefined) => void;
}>({ value: "", setValue: () => null });

export const useHeaderValue = () => {
  const context = React.useContext(HeaderContext);
  return [context.value, context.setValue] as const;
};

export const HeaderContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [value, setValue] = React.useState<string | undefined>("");
  const location = useLocation();
  useEffect(() => setValue(""), [location]);

  return (
    <HeaderContext.Provider value={{ value, setValue }}>
      {children}
    </HeaderContext.Provider>
  );
};
