import { Database } from "db/db";
import { getSpreadSheet } from "googleAPI/getSpreadSheet";
import { getLawList } from "./getLawList";

export const syncLaws = async () => {
  const sheet = await getSpreadSheet({
    id: import.meta.env.VITE_LAWS_IDS_SHEET,
    range: "A:B",
  });
  const ids = sheet.values.reduce((acc: any, cur: any) => [...acc, ...cur], []);
  const laws = await getLawList(ids);

  const parser = new DOMParser();
  const mappedLaws = laws.map(({ id, html }) => {
    const htmlDoc = parser.parseFromString(html, "text/html");
    const el = htmlDoc.getElementById("article");
    const structureEl = htmlDoc.getElementById("Stru")?.querySelector("ul");

    if (el) {
      const link = el.querySelector("link");
      link?.parentElement?.removeChild(link);
    }

    const structure = [...(structureEl?.children ?? [])].flatMap((_el) => {
      const el = _el as HTMLElement;
      if (el.classList.contains("break")) return [];
      if (el.classList.contains("steaky-bottom")) return [];
      const linkEl = el.children[0] as HTMLAnchorElement;

      const item = {
        padding: 0,
        title: el?.textContent,
        anchor: linkEl?.getAttribute("href"),
      };
      if (el.style.paddingLeft)
        item.padding = Number(el.style.paddingLeft.slice(0, -5));
      return [item];
    });

    const title = htmlDoc.querySelector(".page-header");
    return {
      id,
      name: title?.textContent || `Закон - ${id}`,
      el: el?.outerHTML,
      structure,
      html,
    };
  });
  const db = Database.getInstance();
  await db.insertMultiple("law", mappedLaws);
  localStorage.setItem("lawsIds", JSON.stringify(laws.map(({ id }) => id)));
  return laws;
};
