import { addFavoriteLaw, removeFavoriteLaw } from "app/lawsSlice";
import { AppState } from "app/store";
import classNames from "classnames";
import { Database } from "db/db";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useAsync, useDebounce } from "react-use";
import { resolveRoute } from "routes";
import { Collapse } from "ui/Collapse";
import { useHeaderValue } from "ui/HeaderContext";
import { Subheader } from "ui/Subheader";
import { useTheme } from "ui/Theme";
import { getAppLinkToLaw } from "utils";
import { escapeRegExpString } from "utils/escapeRegExpString";
import Flex from "utils/Flex";
import { mapHTMLTree, parseHTML, stringifyHTML } from "utils/HTML";
import styles from "./law.module.scss";

const DEBOUNCE_DELAY = 300;
const SCROLL_DELAY = 300;

export const Law = () => {
  const [value] = useHeaderValue();
  const [debouncedValue, setDebouncedValue] = useState<string | undefined>();
  const [title, setTitle] = useState<string>();
  const [structure, setStructure] = useState<
    { padding: number; title: string; anchor: string }[]
  >([]);
  useDebounce(() => setDebouncedValue(value), DEBOUNCE_DELAY, [value]);

  const favourites = useSelector((state: AppState) => state.laws.favoriteLaws);
  const dispatch = useDispatch();
  const location = useLocation();
  const [dark] = useTheme();
  const { id = "" } = useParams();
  const bodyRef = useRef<HTMLDivElement>(null);
  const { value: law, loading } = useAsync(async () => {
    const db = Database.getInstance();
    return db.getById("law", id);
  }, [id]);

  useEffect(() => {
    if (loading) return;
    if (!law) {
      window.location.replace(
        import.meta.env.VITE_LAWS_ORIGIN + "/laws/show/" + id + location.hash
      );
      return;
    }
    const { el: _element, name, structure } = law as any;
    setTitle(name);
    setStructure(structure);

    const parser = new DOMParser();
    const element = parser
      .parseFromString(_element, "text/html")
      .getElementById("article");
    if (!element) return;
    const links = element.querySelectorAll("a");

    const lawsIds = new Set(
      JSON.parse(localStorage.getItem("lawsIds") || "[]")
    );
    for (const link of links) {
      const href = link.getAttribute("href");
      if (!href) continue;
      if (href.startsWith("/")) {
        const path = href.split("/");
        const id = path.pop();
        const _id = id?.includes("#") ? id.split("#")[0] : id;
        const lawLink = lawsIds.has(id)
          ? resolveRoute("law", { id: _id || "" }) + window.location.hash
          : "#";
        // : import.meta.env.VITE_LAWS_ORIGIN +
        //   "/laws/show/" +
        //   id +
        //   window.location.hash;
        link.setAttribute("target", "");
        link.setAttribute("href", lawLink);
      }
      link.setAttribute("onclick", "disableDefault(event)");
      link.classList.add("lawLinkDisabled");
    }

    let currentNode = false;
    const tree = parseHTML(element.outerHTML);
    if (!tree) return;
    const mappedTree = mapHTMLTree(tree, (node) => {
      if (typeof node !== "string" || !debouncedValue) return node;
      const trimmed = debouncedValue.trim();
      const re = new RegExp(escapeRegExpString(trimmed), "gi");
      let newText = node.replace(re, (m) => {
        const dataAttribute = !currentNode ? "" : 'data-scroll-to="true"';
        return `<span class="searchedText" ${dataAttribute}>${m}</span>`;
      });
      if (!currentNode) currentNode = true;
      return newText;
    });

    element.innerHTML = stringifyHTML(mappedTree);
    bodyRef.current?.appendChild(element);

    const t = setTimeout(() => {
      const el = document.querySelector('[data-scroll-to="true"]');
      if (el)
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
    }, SCROLL_DELAY);

    return () => {
      bodyRef.current?.removeChild(element);
      clearTimeout(t);
    };
  }, [law, loading, debouncedValue]);

  return (
    <Flex.Col gap={16}>
      <Subheader
        title={title}
        favorite={favourites.includes(id)}
        onFavorite={() => {
          if (id === "") return;
          if (favourites.includes(id)) {
            dispatch(removeFavoriteLaw(id));
          } else {
            dispatch(addFavoriteLaw(id));
          }
        }}
      />
      <Collapse
        panelTitle="Зміст"
        panelChildren={
          <Flex.Col gap={8}>
            {structure.map(({ title, anchor, padding }) => (
              <a
                key={anchor}
                style={{ paddingLeft: 16 * padding }}
                onClick={() => {
                  const _anchor = document.querySelector(
                    `[name="${anchor.slice(1)}"]`
                  );
                  _anchor?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "start",
                  });
                }}
              >
                {title}
              </a>
            ))}
          </Flex.Col>
        }
      />
      <div
        ref={bodyRef}
        className={classNames({ [styles.dark]: dark }, styles.box)}
      />
    </Flex.Col>
  );
};
