import { Database } from "db/db";
import { buildTree } from "utils/buildTree";
import { downloadDoc } from "./downloadDoc";
import { downloadImage } from "./downloadImage";
import { getAll } from "./getAll";

const resolveFolder = async (folderId: string) => {
  const db = Database.getInstance();
  const files = await getAll(folderId);

  files.forEach((file) => {
    if (file.parentId === folderId) file.parentId = undefined;
  });

  const imageFiles = files.filter(({ mimeType }) =>
    mimeType.startsWith("image")
  );
  const visibleFiles = files.filter(
    ({ mimeType }) =>
      mimeType === "application/vnd.google-apps.folder" ||
      mimeType === "application/vnd.google-apps.document"
  );
  const [tree, index] = buildTree(visibleFiles);

  await db.insert("folder", {
    id: folderId,
    mimeType: "application/vnd.google-apps.folder",
    name: "Root",
    children: tree.map(({ value: { id } }) => id),
  });

  await Promise.all(
    imageFiles.map(async (item) => {
      const { id, mimeType } = item;
      const data = await downloadImage(id);
      await db.insert("image", {
        id,
        mimeType,
        data,
      });
    })
  );
  const tags: Record<string, string[]> = {};
  await Promise.all(
    Object.values(index).map(async (item) => {
      const { id, mimeType } = item.value;
      if (mimeType === "application/vnd.google-apps.folder") {
        const children = item.children.map(({ value: { id } }) => id);
        const iconItem = files.find(
          ({ name, mimeType, parentId }) =>
            parentId === id && mimeType.startsWith("image") && name === "icon"
        );

        tags[item.value.name] = [`folder-${id}`];

        await db.insert("folder", {
          icon: iconItem && {
            src: iconItem.id,
            mimeType: iconItem.mimeType,
          },
          ...item.value,
          children,
        });

        return;
      }
      if (mimeType === "application/vnd.google-apps.document") {
        const doc = await downloadDoc(id);
        const [firstLine] = doc.split("\n");
        const prefix = "tags: ";
        tags[item.value.name] = [`doc-${id}`];
        if (firstLine.includes(prefix)) {
          const docTags = firstLine
            .slice(prefix.length)
            .split(",")
            .map((tag) => tag.trim());
          for (const tag of docTags) {
            const value = tags[tag]
              ? [...tags[tag], `doc-${id}`]
              : [`doc-${id}`];
            tags[tag] = value;
          }
        }

        await db.insert("doc", { doc, ...item.value });
      }
    })
  );
  localStorage.setItem("treeSearchTags", JSON.stringify(tags));
  return tree;
};

const FOLDERS_TO_SYNC = [
  import.meta.env.VITE_TREE_FOLDER,
  import.meta.env.VITE_LIBRARY_FOLDER,
  import.meta.env.VITE_ABOUT_FOLDER,
  import.meta.env.VITE_IMAGES_FOLDER,
];

export const syncGoogle = async () => {
  const [tree] = await Promise.all(
    FOLDERS_TO_SYNC.map((id) => resolveFolder(id))
  );

  localStorage.setItem("driveTree", JSON.stringify(tree));
  return tree;
};
