import {
  GoogleItem,
  GoogleTreeDocItem,
  GoogleTreeFolderItem,
  GoogleTreeItem,
  StoredGoogleTreeFolderItem,
  StoredGoogleTreeItem,
} from "./types";

export const mapGoogleTreeItems = (data: GoogleItem[]): GoogleTreeItem[] =>
  data.map(({ parents, ...entry }) => ({
    ...entry,
    parentId: parents[0] ?? null,
  }));

export const getFolderFromStorage = (
  id: string
): StoredGoogleTreeFolderItem | null => {
  const item = localStorage.getItem(`folder-${id}`);
  return item ? JSON.parse(item) : null;
};

export const getDocFromStorage = (id: string): GoogleTreeDocItem | null => {
  const item = localStorage.getItem(`doc-${id}`);
  return item ? JSON.parse(item) : null;
};
