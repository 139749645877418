import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Card, Modal, Typography } from "antd";
import Flex from "utils/Flex";
import styles from "./LoadErrorModal.module.scss";

export interface LoadErrorModalProps {
  visible?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

export const LoadErrorModal = ({
  visible,
  onOk,
  onCancel,
}: LoadErrorModalProps): JSX.Element => {
  return (
    <Modal
      centered
      open={visible}
      title={null}
      footer={null}
      onCancel={onCancel}
      closeIcon={<></>}
      className={styles.modal}
    >
      <Card className={styles.card}>
        <Flex.Col>
          <Typography.Title
            level={3}
            style={{ textAlign: "center", userSelect: "none" }}
          >
            Невдалось оновити дані, спробуйте пізніше
          </Typography.Title>
          <Flex.Row gap={18}>
            <Flex.Item flex={1}>
              <Button
                style={{ borderRadius: 4 }}
                type="primary"
                icon={<CheckOutlined />}
                onClick={onOk}
                block
              >
                Ок
              </Button>
            </Flex.Item>
          </Flex.Row>
        </Flex.Col>
      </Card>
    </Modal>
  );
};
